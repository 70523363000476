
import { defineComponent, onBeforeMount, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { ApiBase, ApiAccount, ApiChanel } from "@/core/api";
import AddAddressModal from "@/views/influencer-platform/influencer-accounts/AddAddressModal.vue";
import AddConnectionModal from "@/views/influencer-platform/influencer-accounts/AddConnectionModal.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import mixin from "@/mixins";
import { Plus } from "@element-plus/icons-vue";
import JwtService from "@/core/services/JwtService";
import { useI18n } from "vue-i18n";
import {
  commonChangeDefaultDate,
  setModuleBCN,
} from "@/core/directive/function/common";
import { checkPermissionFlag } from "@/directives/permission";

interface User {
  id: number;
  username: string;
  email: string;
  firstname: string;
  lastname: string;
  language: string;
  advanced_manage: number;
}

interface TaggingItem {
  label: string;
  value: number;
}
interface PhotoItem {
  name: string;
  url: string;
}

export default defineComponent({
  name: "account-information",
  components: {
    AddAddressModal,
    AddConnectionModal,
    Plus,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const deleteButton = ref<HTMLElement | null>(null);

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const userInfo = ref<User>();
    userInfo.value = store.getters.currentUser;

    watch(
      () => store.getters.currentUser,
      (newVal) => {
        userInfo.value = newVal;
      }
    );

    const formData = ref({
      id: route.params.id,
      status: 0,
      crm_account_id: "",
      crm_account_status: 10,
      lifecycle: "",
      salutation: "",
      first_name: "",
      last_name: "",
      source: "",
      channel: "",
      industry: "",
      mb_segmentation: [],
      email: "",
      phone: "",
      birthday: "",
      registration_date: "",
      first_login_time: "",
      photo: "",
      // photo_url: "",
      personal_id: "",
      personal_id_front: "",
      // personal_id_front_url: "",
      personal_id_reverse: "",
      // personal_id_reverse_url: "",
      mobile_country_code: "",
      mobile: "",
      location_of_mobile: "",
      wechat_account: "",
      bc_quota: "",
      stage: "",
      assigned_group: 0,
      cycle_status: 0,
      assigned_cycle: 0,
      assigned_cycle_list: [],
      system_id: 0,
      contact_start: "",
      expiry_date: "",
      notes: "",
      created_at: null,
      updated_at: null,
      created_uid: "",
      updated_uid: "",
      __show: {
        assigned_cycle: "无",
        created_user: "",
        updated_user: "",
      },
    });

    const lifecycleOptions = ref([]);
    const industryOptions = ref([]);
    const channelOptions = ref([]);
    const sourceOptions = ref([]);
    const addressTypeOptions = ref<Array<TaggingItem>>([]);
    const accountTypeOptions = ref<Array<TaggingItem>>([]);
    const countryOptions = ref<Array<TaggingItem>>([]);
    const phoneCodeOptions = ref([]);
    const groupOptions = ref([]);
    const mbSegmentationOptions = ref<Array<TaggingItem>>([]);

    const addressTableData = ref([]);
    const addressId = ref(0);

    const connectionTableData = ref([]);
    const connectionId = ref(0);

    const membersTableData = ref([]);

    const idCardRegexp =
      /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
    const mobileRegexp = /^1[23456789]\d{9}$/;
    const rules = ref({
      salutation: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      first_name: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      last_name: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      source: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      channel: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      industry: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      registration_date: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      mobile: [
        {
          required: true,
          asyncValidator: (rule, value) => {
            return new Promise<void>((resolve, reject) => {
              if (!value) {
                reject(t("common.isRequired"));
              } else if (!mobileRegexp.test(value)) {
                t("influencerAccount.enterMobile");
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
      location_of_mobile: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      assigned_group: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      phone: [
        {
          required: false,
          asyncValidator: (rule, value) => {
            return new Promise<void>((resolve, reject) => {
              if (!mobileRegexp.test(value) && value != "") {
                reject(t("influencerAccount.enterMobile"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
      personal_id: [
        {
          required: false,
          asyncValidator: (rule, value) => {
            return new Promise<void>((resolve, reject) => {
              if (!idCardRegexp.test(value) && value != "") {
                reject(t("influencerAccount.PleaseCardId"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
    });
    const uploadAccountdPhoto = ApiAccount.uploadAccountdPhoto(); // 头像上传
    const photoList = ref<Array<PhotoItem>>([]); // 头像列表
    const uploadBcPersonIdReverse = ApiAccount.uploadBcPersonIdReverse(); // 身份证背面上传
    const BcPersonIdReverseList = ref<Array<PhotoItem>>([]); // 身份证背面列表
    const uploadBcPersonIdFront = ApiAccount.uploadBcPersonIdFront(); // 身份证正面上传
    const BcPersonIdFrontList = ref<Array<PhotoItem>>([]); // 身份证背面列表
    const dialogImageUrl = ref<string>("");
    const dialogVisible = ref<boolean>(false);

    // 头像
    const handlePhotoRemove = (file, fileList) => {
      photoList.value = [];
      formData.value.photo = "";
    };
    const handlePhotoChange = (response, file, fileList) => {
      formData.value.photo = response.data.path;
    };
    // 正面
    const handlePersonalFrontIDRemove = (file, fileList) => {
      BcPersonIdFrontList.value = [];
      formData.value.personal_id_front = "";
    };
    const handlePersonalFrontIDChange = (response, file, fileList) => {
      formData.value.personal_id_front = response.data.path;
    };
    // 背面
    const handlePersonalReverseIDRemove = (file, fileList) => {
      BcPersonIdReverseList.value = [];
      formData.value.personal_id_reverse = "";
    };
    const handlePersonalReverseIDChange = (response, file, fileList) => {
      formData.value.personal_id_reverse = response.data.path;
    };
    const uploadHeaders = ref({
      Authorization: `Bearer ${JwtService.getToken()}`,
    });

    // 预览
    const handlePictureCardPreview = (file) => {
      dialogImageUrl.value = file.url;
      dialogVisible.value = true;
    };

    const getAccountData = async () => {
      loading.value = true;
      const { data } = await ApiAccount.getAccountInfo({
        id: route.params.id,
      });
      loading.value = false;
      if (data.code == 0) {
        formData.value = data.data;
        if (data.data.photo_url) {
          photoList.value.splice(0, photoList.value.length, {
            name: "",
            url: data.data.photo_url,
          });
        }
        if (data.data.personal_id_reverse_url) {
          BcPersonIdReverseList.value.splice(
            0,
            BcPersonIdReverseList.value.length,
            {
              name: "",
              url: data.data.personal_id_reverse_url,
            }
          );
        }
        if (data.data.personal_id_front_url) {
          BcPersonIdFrontList.value.splice(
            0,
            BcPersonIdFrontList.value.length,
            {
              name: "",
              url: data.data.personal_id_front_url,
            }
          );
        }
      }
    };

    const getChannelOptions = () => {
      ApiChanel.getDistributionDataSource({ parent_id: 33 })
        .then(({ data }) => {
          if (data.code == 0) {
            channelOptions.value = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getPhoneCodeOptions = () => {
      ApiBase.getPhoneCodeData()
        .then(({ data }) => {
          if (data.code == 0) {
            phoneCodeOptions.value = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getCountryOptions = async () => {
      const { data } = await ApiBase.getCountryData();
      if (data.code == 0) {
        countryOptions.value = data.data;
      }
    };

    const getDropdownOptions = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: [
          "distribution_industry",
          "global_account_source",
          "global_address_type",
          "global_social_connection_type",
          "influencer_lifecycle",
          "influencer_mb_segmentation",
        ],
      });
      if (data.code == 0) {
        lifecycleOptions.value = data.data.influencer_lifecycle.items;
        industryOptions.value = data.data.distribution_industry.items;
        sourceOptions.value = data.data.global_account_source.items;
        addressTypeOptions.value = data.data.global_address_type.items;
        accountTypeOptions.value =
          data.data.global_social_connection_type.items;
        mbSegmentationOptions.value =
          data.data.influencer_mb_segmentation.items;
      }
    };

    const getGroupOptions = async () => {
      const { data } = await ApiBase.getInfluencerGroupSourceData({
        max_item: "all",
      });
      if (data.code == 0) {
        groupOptions.value = data.data;
      }
    };

    const getCrmAccountStatus = (val) => {
      const status = new Map([
        ["0", t("influencerAccount.draft")],
        ["5", t("influencerAccount.inactivated")],
        ["10", t("influencerAccount.suspended")],
        ["12", t("influencerAccount.Inivited")],
        ["20", t("influencerAccount.active")],
        ["90", t("influencerAccount.Disabled")],
      ]);
      if (val == 0 || val == 5) {
        return (
          '<span class="badge badge-light">' +
          status.get(String(val)) +
          "</span>"
        );
      } else if (val == 10) {
        return (
          '<span class="badge badge-warning">' +
          status.get(String(val)) +
          "</span>"
        );
      } else if (val == 12) {
        return (
          '<span class="badge badge-info">' +
          status.get(String(val)) +
          "</span>"
        );
      } else if (val == 90) {
        return (
          '<span class="badge badge-secondary">' +
          status.get(String(val)) +
          "</span>"
        );
      } else if (val == 20) {
        return (
          '<span class="badge badge-success">' +
          status.get(String(val)) +
          "</span>"
        );
      }
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate(async (valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          const { data } = await ApiAccount.updateAccount(formData.value);
          loading.value = false;
          submitButton.value?.removeAttribute("data-kt-indicator");
          if (data.code == 0) {
            showFormSubmitSuccessMsg();
          } else {
            showServerErrorMsg(data);
          }
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const deleteAccount = () => {
      Swal.fire({
        text: "Are you sure you would like to deactivate your account?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (deleteButton.value) {
            deleteButton.value.setAttribute("data-kt-indicator", "on");
          }
          loading.value = true;
          const { data } = await ApiAccount.deleteAccount({
            id: route.params.id,
          });

          loading.value = false;
          deleteButton.value?.removeAttribute("data-kt-indicator");
          if (data.code == 0) {
            Swal.fire({
              text: "Your company has been deactivated!",
              icon: "success",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              router.push({ name: "accounts-index" });
            });
          }
        }
      });
    };

    const handleAddAddress = () => {
      addressId.value = 0;
    };

    const handleEditAddress = (index, row) => {
      addressId.value = row.id;
    };

    const updateAddressList = async () => {
      loading.value = true;
      const { data } = await ApiAccount.getAddressList({
        account_id: route.params.id,
      });
      loading.value = false;
      if (data.code == 0) {
        addressTableData.value = data.data.items;
      }
    };

    const handleDeleteAddress = (index, row) => {
      Swal.fire({
        text: "Are you sure you would like to delete it?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          loading.value = true;
          const { data } = await ApiAccount.deleteAddress({
            id: row.id,
          });
          loading.value = false;
          if (data.code == 0) {
            Swal.fire({
              text: "Deleted successfully!",
              icon: "success",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              updateAddressList();
            });
          }
        }
      });
    };

    const handleAddConnection = () => {
      connectionId.value = 0;
    };

    const handleEditConnection = (index, row) => {
      connectionId.value = row.id;
    };

    const updateConnectionList = async () => {
      loading.value = true;
      const { data } = await ApiAccount.getSocialConnectionList({
        account_id: route.params.id,
      });
      loading.value = false;
      if (data.code == 0) {
        connectionTableData.value = data.data.items;
      }
    };

    const handleDeleteConnection = (index, row) => {
      Swal.fire({
        text: "Are you sure you would like to delete it?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          loading.value = true;
          const { data } = await ApiAccount.deleteSocialConnection({
            id: row.id,
          });
          loading.value = false;
          if (data.code == 0) {
            Swal.fire({
              text: "Deleted successfully!",
              icon: "success",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              updateConnectionList();
            });
          }
        }
      });
    };

    const getAddressTypeLabel = (row, column, cellValue, index) => {
      const obj = addressTypeOptions.value.find((item) => {
        return cellValue == item.value;
      });
      return obj?.label;
    };

    const getCountryName = (row, column, cellValue, index) => {
      const obj = countryOptions.value.find((item) => {
        return cellValue == item.value;
      });
      return obj?.label;
    };

    const getAaccountTypeLabel = (row, column, cellValue, index) => {
      const obj = accountTypeOptions.value.find((item) => {
        return cellValue == item.value;
      });
      return obj?.label;
    };

    const getAaccountStatusLabel = (row, column, cellValue, index) => {
      return cellValue == 0
        ? t("influencerAccount.unverified")
        : t("influencerAccount.verified");
    };

    const backToList = () => {
      if (route.path.indexOf("account-conversions") != -1) {
        router.push("/interactive-marketing/account-conversions");
      } else {
        router.push("/influencer-platform/influencer-accounts");
      }
    };

    onBeforeMount(() => {
      getDropdownOptions();
      getChannelOptions();
      getCountryOptions();
      getGroupOptions();
      getPhoneCodeOptions();
      getAccountData();
      updateAddressList();
      updateConnectionList();
    });

    onMounted(() => {
      setModuleBCN(t, route, router);
    });

    return {
      t,
      commonChangeDefaultDate,
      checkPermissionFlag,
      userInfo,
      loading,
      formData,
      rules,
      formRef,
      submitButton,
      deleteButton,
      lifecycleOptions,
      industryOptions,
      channelOptions,
      sourceOptions,
      phoneCodeOptions,
      mbSegmentationOptions,
      groupOptions,
      addressTableData,
      addressId,
      connectionTableData,
      connectionId,
      membersTableData,
      uploadHeaders,
      uploadAccountdPhoto,
      uploadBcPersonIdReverse,
      uploadBcPersonIdFront,
      dialogVisible,
      dialogImageUrl,
      photoList,
      BcPersonIdFrontList,
      BcPersonIdReverseList,
      handlePhotoRemove,
      handlePhotoChange,
      handlePersonalFrontIDRemove,
      handlePersonalFrontIDChange,
      handlePersonalReverseIDRemove,
      handlePersonalReverseIDChange,
      handlePictureCardPreview,
      submit,
      getAccountData,
      getCrmAccountStatus,
      deleteAccount,
      handleAddAddress,
      handleEditAddress,
      handleDeleteAddress,
      updateAddressList,
      handleAddConnection,
      handleEditConnection,
      handleDeleteConnection,
      updateConnectionList,
      getAddressTypeLabel,
      getCountryName,
      getAaccountTypeLabel,
      getAaccountStatusLabel,
      backToList,
    };
  },
});
